import {Controller} from "@hotwired/stimulus"

/**
 TabsController allows to toggle between different tabs. The controlled element
 must contain `nav` with elements pointing to the tabs (`aria-controls`) and
 the tabs themselves (matching `id`).

 The controller cross-links the nav items with the tabs (adding `id` to the nav
 item and `aria-labelledby` to the tab) and adds click->tabs#show to the nav
 items. It toggles currentClass on the parent of the nav items on click. It
 automatically detects the initially active tab based on the URL hash (if used)
 or selects the first tab.

    %div{data: { controller: :tabs,
                 tabs_current_class: 'current'} }
      %nav{data: {tabs_target: 'navigation'} }
        %a{href: '#tab1', aria: {controls: 'tab1'}} Tab 1
        %a{href: '#tab2', aria: {controls: 'tab2'}} Tab 2

      %div{id: 'tab1', data: { tabs_target: 'tab' }} Tab 1 content
      %div{id: 'tab2', data: { tabs_target: 'tab' }} Tab 2 content
 */
export default class extends Controller {
  static classes = ['current']
  static targets = ['tab', 'navigation']
  static values = {displayed: String}

  connect() {
    super.connect();
    this._crossLinkNavigationItemsAndTabs();
    this._detectActiveTab()
    this.ensureTabsVisibility()
    this.showHandler = this._showHandler.bind(this)
    this.element.addEventListener('tabs:show', this.showHandler)
  }

  _crossLinkNavigationItemsAndTabs() {
    this.tabTargets.forEach(tab => {
      const navItem = this.tabsMenuItem(tab)
      navItem.id = `tab-nav-${tab.id}`
      navItem.dataset.action = 'click->tabs#show'
      tab.setAttribute('aria-labelledby', navItem.id);
    })
  }

  _detectActiveTab() {
    let hashMatched = false
    if (window.location.hash) {
      const urlHashId = window.location.hash.slice(1)

      this.tabTargets.forEach(tab => {
        if (tab.id === urlHashId) {
          hashMatched = true;
          this.displayedValue = tab.id
        }
      })
    }

    if (!this.displayedValue && this.tabTargets.length > 0) {
      this.displayedValue = this.tabTargets[0].id
    }
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('tabs:show', this.showHandler);
  }

  show(e) {
    const tab = this.tabTargets.find(tab => {
      return tab.id === e.currentTarget.attributes['aria-controls'].value;
    });
    if (tab) {
      this._show(tab);
    }
  }

  _show(tab) {
    this.displayedValue = tab.id
    this.ensureTabsVisibility()
  }

  ensureTabsVisibility() {
    this.tabTargets.forEach(tab => {
      const tabsMenuItemTab = this.tabsMenuItem(tab);
      if (!tabsMenuItemTab) return;
      if (tab.id === this.displayedValue) {
        tab.hidden = false
        tabsMenuItemTab.parentNode.classList.add(...this.currentClasses)
      } else {
        tab.hidden = true
        tabsMenuItemTab.parentNode.classList.remove(...this.currentClasses)
      }
    })
  }

  tabsMenuItem(tab) {
    return this.navigationTarget.querySelector(`[aria-controls="${tab.id}"]`)
  }

  _showHandler(e) {
    this._show(e.target);
  }
}