import {fetches} from './fetches';

export const replaceByFetch = controller => {
  fetches(controller);
  Object.assign(controller, {
    replaceByFetch(url, method, opts = {}) {
      opts = {
        alertOnError: false,
        useErrorHandling: false,
        ...opts,
      }

      const spinnerTarget = opts.spinnerTarget || this.element;
      const frame = opts.frame || this.element;
      let data = opts.data || null;
      const successHandler = opts.successHandler || function (text, frame, replaceMode) {
        if (text === '' && !!this.removeOnEmptyResponseValue) {
          frame.remove();
        } else {
          this.replaceFrame(text, frame, replaceMode);
        }
        if (opts.onSuccess) {
          opts.onSuccess();
        }
      }.bind(this)

      this.showSpinner(spinnerTarget);

      if (method === 'GET' && data !== null) {
        url = controller._addFormDataToUrl(url, data);
        data = null;
      }

      this.fetchPartial(
        url,
        {
          method: method,
          body: data,
        }
      ).then(res => {
        if (res.status >= 200 && res.status < 300) {
          res.text().then(text => {
            successHandler(text, frame, opts.replaceMode);
          })
        }  else {
          res.text().then(text => {
            if (opts.alertOnError) {
              console.error(`Failed to fetch ${url} with status ${res.status}: ${text}`);
              alert(text);
            } else if (opts.useErrorHandling) {
              successHandler(text, frame, opts.replaceMode, true);
            } else {
              successHandler(text, frame, opts.replaceMode);
            }
          })
        }
      }).finally(_ => this.hideSpinner(spinnerTarget))
    },

    showSpinner(spinnerTarget = this.element) {
      spinnerTarget.dataset.controller = `${spinnerTarget.dataset.controller} spinner`;
    },

    hideSpinner(spinnerTarget = this.element) {
      spinnerTarget.dataset.controller = spinnerTarget.dataset.controller.split(' ').filter(str => {
        return str !== 'spinner'
      }).join(' ');
    },

    replaceFrame(text, frame = this.element, replaceMode = 'innerHTML') {
      if (replaceMode === 'innerHTML') {
        frame.innerHTML = text;
      } else {
        const parentElement = frame.parentElement;
        frame.outerHTML = text;
        // the frame is replaced, reference is no longer valid, we go one level up
        frame = parentElement;
      }
      frame.querySelectorAll('script[data-eval]').forEach(script => {
        /* all you need is replace script selector
           with document.currentScript in templates */

        /* create new script element */
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute('type', 'text/javascript');

        /* copy script tag content to newly created one */
        scriptElement.text = script.textContent;
        /* remove original script */
        script.remove();

        /* append newly created script to frame */
        frame.appendChild(scriptElement);
      })
    },

    _addFormDataToUrl(url, data) {
      const parsedUrl = new URL(url, window.location);
      data.forEach((value, key) => {
        if (key !== 'authenticity_token') {
          parsedUrl.searchParams.append(key, value);
        }
      });
      return parsedUrl.toString();
    }
  });
};