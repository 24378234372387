import {Controller} from "@hotwired/stimulus"
import {replaceByFetch} from "./mixins/replace_by_fetch";
import {formSerialization} from "./mixins/form_serialization";

/*
 * Search controller sends a query to the server and displays the results.
 *
 * Example:
 *
 *      %form{method: 'GET',
 *            action: url_for_searched_objects_retrieval,
 *            data: { controller: 'search',
 *                    search: { target: 'form',
 *                              wait: { value: 1200 },
 *                              hidden_class: 'hidden' }}
 *        %input{type: 'text',
 *               name: 'q',
 *               data: { action: 'input->search#search',
 *                       search_target: 'input' }}
 *        %div{ data: { search_target: 'spinner' }}
 *          %button{data: { action: 'click->search#submit' }}
 *        %div{data: { search_target: 'results hideable' }}
 *
 */
export default class extends Controller {
    static values = {
        wait: 750,
    }
    static targets = ['form', 'input', 'results', 'spinner', 'hideable']
    static classes = ['hidden']

    connect() {
        super.connect()
        formSerialization(this);
        replaceByFetch(this)
        this._addToggleHideableListener()
    }

    disconnect() {
        this._removeToggleHideableListener()
        super.disconnect();
    }

    _addToggleHideableListener() {
        if (!this._hideableListener) {
            this._hideableListener = this._toggleHideableListener.bind(this);
            window.addEventListener('click', this._hideableListener)
        }
    }

    _removeToggleHideableListener() {
        if (this._hideableListener) {
            window.removeEventListener('click', this._hideableListener)
        }
    }

    _toggleHideableListener(event) {
        if (this._clickedOutside(event)) {
            this.abandon()
        } else {
            this._showHideable()
        }
    }

    _clickedOutside(event) {
        const clickedWithin =
            this.element === event.target ||
            this.element.contains(event.target)

        return !clickedWithin
    }

    abandon() {
        // this can happen when hideable is displayed after the same click event
        // by another controller
        if (!this.hasHideableTarget) {
            return
        }
        this.hideableTarget.classList.add(this.hiddenClass)
        if (this.hideableTarget.nodeName === 'DIALOG') {
            this.hideableTarget.close();
        }
    }

    _showHideable() {
        this.hideableTarget.classList.remove(this.hiddenClass)
        this._addToggleHideableListener()
    }

    search(event) {
        event.preventDefault()
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this._sendQuery(event.target.value)
        }, this.waitValue)
    }

    submit(event) {
        event.preventDefault()
        clearTimeout(this.timeout)
        this._sendQuery(this.inputTarget.value)
    }

    _sendQuery(query) {
        if (!query || query.length === 0) {
            this.resultsTarget.innerHTML = ''
            return
        }

        let action = this.formTarget.action || this.formTarget.dataset.turboFrameUrl;
        let method = this.formTarget.method || this.formTarget.dataset.method;
        this.replaceByFetch(action, method.toUpperCase(), this._fetchOptions())
        this._showHideable()
    }

    _fetchOptions() {
        let options = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')['content'],
                'X-Turbo-Frame': 'no-layout',
                'Accept': 'text/html, application/json',
            },
            spinnerTarget: this.spinnerTarget,
            successHandler: (html) => {
                this.resultsTarget.innerHTML = html
            },
        }

        const formData = new FormData();
        this.collectInputValues(this.formTarget, formData)
        if (Array.from(formData.entries()).length === 0) {
            return options
        }

        options.data = formData

        return options
    }
}