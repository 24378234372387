export const formSerialization = controller => {
  Object.assign(controller, {
    collectInputValues(el, data) {
      el.querySelectorAll('input[type=hidden]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=text]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=search]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=date]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=datetime-local]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=range]:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('input[type=radio]:checked:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
      el.querySelectorAll('select:not(:disabled)').forEach(select => {
        data.append(select.name, select.value);
      });
      el.querySelectorAll('textarea:not(:disabled)').forEach(select => {
        data.append(select.name, select.value);
      });
      el.querySelectorAll('input[type=checkbox]:checked:not(:disabled)').forEach(input => {
        data.append(input.name, input.value);
      });
    }
  });
};