function showSpinner(e) {
  if (!e.target.dataset['spinnerId']) {
    return;
  }
  const spinner = document.getElementById(e.target.dataset['spinnerId']);
  if (!spinner) {
    return;
  }

  spinner.classList.remove('hidden');
}

function hideSpinner(e) {
  if (!e.target.dataset['spinnerId']) {
    return;
  }
  const spinner = document.getElementById(e.target.dataset['spinnerId']);
  if (!spinner) {
    return;
  }

  spinner.classList.add('hidden');
}

const asyncHandler = function(url, fetchOptions, e){
  showSpinner(e);

  fetch(url, fetchOptions)
    .then(response => {
      if (response.ok || response.status === 400) {
        return response.text();
      }
      throw(response);
    })
    .then(response => {
      if (!e.target.dataset['replaceIds']) {
        console.error('No IDs to replace specified');
        return;
      }

      const replaceIds = e.target.dataset['replaceIds'].split(' ');
      const newContent = document.createElement('div');
      newContent.innerHTML = response;

      Array.prototype.forEach.call(replaceIds, function (replaceId) {
        const elementToReplace = document.getElementById(replaceId);
        if (!elementToReplace) {
          console.error('Element to be replaced not found', replaceId);
          return;
        }

        Array.prototype.forEach.call(newContent.getElementsByClassName('to_replace')[0].childNodes, function(el) {
          if (el.id === replaceId) {
            elementToReplace.parentNode.replaceChild(
              el,
              elementToReplace
            );
          }
        });
      });

      Array.prototype.forEach.call(newContent.getElementsByClassName('to_append'), function (el) {
        const targetId = el.dataset['targetId'];
        if (!targetId) {
          console.error('No ID specified for appending new content');
          return;
        }

        const target = document.getElementById(targetId);
        if (!target) {
          console.error('The element to which new content should be appended does not exist', targetId);
          return;
        }

        target.appendChild(el.firstElementChild);
      });

      Array.prototype.forEach.call(newContent.getElementsByClassName('to_prepend'), function (el) {
        const targetId = el.dataset['targetId'];
        if (!targetId) {
          console.error('No ID specified for prepending new content');
          return;
        }

        const target = document.getElementById(targetId);
        if (!target) {
          console.error('The element to which new content should be prepended does not exist', targetId);
          return;
        }

        target.prepend(el.firstElementChild);
      });
    })
    .catch(error => {
      console.error(error);
    })
    .finally(_ => hideSpinner(e));
};

const asyncFormHandler = function (e) {
  if (!e.target.dataset['async']) {
    return;
  }

  e.preventDefault();

  asyncHandler(e.target.action, {
    method: e.target.method.toUpperCase(),
    body: new FormData(e.target)
  }, e);
};


document.addEventListener('readystatechange', () => {
  document.addEventListener('submit', asyncFormHandler);
  document.addEventListener('click', function (e) {
    if ((e.target.getAttribute('class') || '').indexOf('js-async') < 0) {
      return;
    }

    asyncHandler(e.target.dataset.url, {}, e);
  });
})