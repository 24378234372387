import {Controller} from "@hotwired/stimulus"
import {disabling} from "./mixins/disabling";

/**
 * Alternatives controller allows to display different content based on
 * a <select>'s current value or a selected radio, and in data attribute
 * alternatives. Not all values must have a content, more pieces of content can
 * depend on the same option. A piece of content can depend on multiple options
 * (any of them needs to be selected).
 *
 * All alternative targets listed in the active item are shown, all others are
 * hidden.
 *
 * -# select
 *   %fieldset{ data: { controller: 'alternatives', alternatives_hidden_class: 'hidden' } }
 *
 *    %select{ data: { action: 'change->alternatives#select' } }
 *      %option{value: 'foo'} Foo
 *      %option{value: 'bar zoo'} Bar & Zoo
 *      %option{value: 'zoo'} Zoo
 *    %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'foo bar' } }
 *      Only displayed when Foo or Bar is selected
 *    %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'zoo' } }
 *      Only displayed when Zoo is selected
 *
 * -# select with value and alternatives
 *     %fieldset{ data: { controller: 'alternatives', alternatives_hidden_class: 'hidden' } }
 *
 *      %select{ data: { action: 'change->alternatives#select' } }
 *        %option{value: 'foo', data-alternatives: 'bar'} Foo
 *      %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'foo' } } FooDiv
 *      %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'bar' } } BarDiv
 *        Both FooDiv and BarDiv would be made visible when `Foo` value is selected
 *
 * -# radios
 *   %fieldset{ data: { controller: 'alternatives', alternatives_hidden_class: 'hidden' } }
 *    %label
 *      %input{type: :radio,
 *             name: 'toggler',
 *             value: 'foo',
 *             checked: true,
 *             data: { action: 'click->alternatives#select' } }
 *      Foo
 *    %label
 *      %input{type: :radio,
 *             name: 'toggler',
 *             value: 'bar zoo',
 *             data: { action: 'click->alternatives#select' } }
 *      Bar & Zoo
 *    %label
 *      %input{type: :radio,
 *             name: 'toggler',
 *             value: 'zoo',
 *             data: { action: 'click->alternatives#select' } }
 *      Zoo
 *
 *    %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'foo' } }
 *      Only displayed when Foo is selected
 *    %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'zoo' } }
 *      Only displayed when Zoo is selected
 *
 * -# radio with value and alternatives
 *     %fieldset{ data: { controller: 'alternatives', alternatives_hidden_class: 'hidden' } }
 *
 *    %label
 *      %input{type: :radio,
 *             name: 'toggler',
 *             value: 'foo',
 *             checked: true,
 *             data: { action: 'click->alternatives#select', alternatives: 'bar' } } Foo
 *      %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'foo' } } FooDiv
 *      %div{ data: { alternatives_target: 'alternative', alternatives_alternative: 'bar' } } BarDiv
 *        Both FooDiv and BarDiv would be made visible when `Foo` option is selected
 */
export default class extends Controller {
    static targets = ['alternative']
    static classes= ['hidden']

    connect() {
        disabling(this);
        this.element.querySelectorAll('[data-action]').forEach(el => {
            if (!this.scope.containsElement(el)) {
                return
            }

            if (el.dataset['action'].includes(`->alternatives#select`)) {
                this.select({target: el})
            }
        })
    }

    select(event) {
        const alternatives = []
        if (event.target.type === 'radio') {
            if (event.target.checked) {
                alternatives.push(event.target.value.split(' '));
                if ('alternatives' in event.target.dataset) {
                    alternatives.push(event.target.dataset.alternatives.split(' '));
                }
                this.showAlternatives(alternatives.flat())
            }
        } else if (event.target.type === 'select-one') {
            Array(...event.target.options).forEach(option => {
                if (option.selected) {
                    alternatives.push(option.value.split(' '));
                    if ('alternatives' in option.dataset) {
                        alternatives.push(option.dataset.alternatives.split(' '));
                    }
                    this.showAlternatives(alternatives.flat())
                }
            })
        } else {
            console.error(`unsupported toggler for ${this.context.module.identifier}`)
        }

        // create a new event to notify other controllers
        const alternativesEvent = new CustomEvent('alternatives:select', {})
        window.dispatchEvent(alternativesEvent)
    }

    showAlternatives(alternativesToShow) {
        this.alternativeTargets.forEach(el => {
            const registeredAlternatives = el.dataset['alternativesAlternative'].split(' ');

            if (registeredAlternatives.filter(v => alternativesToShow.includes(v)).length > 0) {
                this.enable(el);
            } else {
                this.disable(el);
            }
        })
    }
}