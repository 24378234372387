import {Controller} from "@hotwired/stimulus"

/**
 * Toggle secret controller allows to render a placeholder and a button instead
 * of a secret. The button toggles the visibility of the secret.
 *
 * It the `content` is an `<input>` it toggles type between `password`
 * and `text`. Otherwise, it requires `toggle-secret_placeholder` and
 * `toggle-secret_secret` data attributes and swaps them as text content
 * of the element.
 *
 * %div{ data: { controller: 'toggle-secret' }}
 *  %input{ type: :password, data: { 'toggle-secret_target': 'content' }, value: secret}
 *  %button{ data: { action: 'click->toggle-secret#toggle'}}}
 *    toggle secret!
 *
 * OR
 *
 * %div{ data: { controller: 'toggle-secret'}}
 *  %div{ data: { 'toggle-secret_target': 'content',
 *               'toggle-secret_placeholder': placeholder,
 *               'toggle-secret_secret': secret_value}}
 *    = placeholder
 *  %button{ data: { action: 'click->toggle-secret#toggle'}}}
 *    toggle secret!
 *
 */
export default class extends Controller {
  static targets = ['content']

  toggle() {
    if (this.contentTarget.nodeName === 'INPUT') {
      if (this.contentTarget.type === 'password') {
        this.contentTarget.type = 'text';
      } else {
        this.contentTarget.type = 'password';
      }
    } else {
      if (this.contentTarget.dataset['toggleSecretRevealed'] === 'true') {
        this.contentTarget.innerText = this.contentTarget.dataset['toggleSecretPlaceholder'];
        this.contentTarget.dataset['toggleSecretRevealed'] = 'false';
      } else {
        this.contentTarget.innerText = this.contentTarget.dataset['toggleSecretSecret'];
        this.contentTarget.dataset['toggleSecretRevealed'] = 'true';
      }
    }
  }
}